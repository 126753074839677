<template>
    <div>
       <h1>Админ панель</h1>
       <router-link to="/addnews">Добавить новость</router-link>
    </div>
</template>

<script>
    
    export default {
        created(){
            document.title = 'Админ панель'
        }
    }
</script>

<style lang="scss">
    button{
        padding: 12px;
    }
</style>